import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { DismissT } from '../Dismiss/Dismiss';
import { themeMap } from '../Themes';
import type { ThemeMapTypeT } from '../Themes';
import { Box, type BoxT } from '../Box';
import { Dismiss } from '../Dismiss/Dismiss';
import { Group, type GroupActionT, type ActionPatternT } from '../Group';

type LayerT = 'flat' | 'close' | 'far';

export interface PromptGenericT extends BoxT, DismissT, GroupActionT {
  layer?: LayerT | undefined;
  theme?: ThemeMapTypeT | undefined;
  autoFocus?: boolean;
  hasDismiss?: boolean;
  actionPattern?: ActionPatternT;
}

const boxLayerMap = {
  flat: undefined,
  close: 'shadow-[0_3px_15px_rgba(0,0,0,0.2)]',
  far: 'shadow-[0_6px_25px_rgba(0,0,0,0.2)]',
};

export const PromptGeneric = ({
  children,
  onDismiss,
  dismissLabel,
  hasDismiss = true,
  layer = 'flat',
  theme,
  actions,
  autoFocus,
  actionPattern = 'z',
  className,
  ...props
}: PromptGenericT) => {
  const content = (
    <Box
      className={cn(
        `block w-auto overflow-auto p-6 text rounded-3xl bg-surface ${onDismiss ? 'pr-14' : 'pr-6'} ${boxLayerMap[layer]}`,
        className,
      )}
      {...props}
    >
      {hasDismiss && (
        <Box className="absolute top-0 right-0 ">
          <Dismiss
            onDismiss={onDismiss}
            dismissLabel={dismissLabel}
            p="space6"
          />
        </Box>
      )}
      {children}
      <Group
        autoFocus={autoFocus}
        actions={actions}
        className="mt-2 sm:mt-4"
        actionPattern={actionPattern}
      />
    </Box>
  );
  // Conditional theme in order to not double the rendering when for example
  // Popover & Modal already has a theme due to the arrow coloring and backdrop.
  if (theme) {
    const T = themeMap[theme];
    return <T>{content}</T>;
  }
  return content;
};
